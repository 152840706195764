import React from 'react';

// libs
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { LicenseInfo } from '@mui/x-license-pro';


// components
import App from './components/App/App';

// assets
import './index.scss';


const theme = createTheme({
    typography: {
        fontFamily: "Nunito Sans, sans-serif",
        color: '#374047'
    },
});

LicenseInfo.setLicenseKey('3e8869c9cf4ad3a53aef7d55d338e22eTz00NzE2OSxFPTE2ODkxOTM2NzQyMDUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
