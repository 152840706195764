import React, {useEffect, useState, memo} from 'react';

// libs
import {Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell} from "recharts";
import {Box} from "@mui/material";
import {Typography} from "@material-ui/core";

// components
import {useWindowSize} from "../../utils/useWindowsSize";

// assets
import styles from '../SimplePieChart/SimplePieChart.module.scss';

const RADIAN = Math.PI / 180;

const customLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const CustomTooltip = ({active, payload}) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p>{payload[0].payload.parent_make}</p>
                <p>{`Category: ${payload[0].payload['Category']}`}</p>
                <p>{`Total Recalls: ${payload[0].payload['Total Recalls']}`}</p>
            </div>
        );
    }
    return null;
};

const customLegend = ({payload}) => {
    return (
        <div>
            {
                payload.map((entry, index) => (
                    <div key={`item-${index}`} style={{display: 'flex', gap: 5, marginBottom: 10}}>
                        <span style={{width: 30, backgroundColor: entry.color}}/><span>{entry.payload.Category}</span>
                    </div>
                ))
            }
        </div>
    );
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];
const SimplePieChart = ({selectedRecallOem, recallDropdownData, tabValue, rows}) => {
    const {width} = useWindowSize();

    const [filteredRows, setFilteredRows] = useState(null);
    const [size, setSize] = useState({width: '100%', height: 600});

    useEffect(() => {
        if (tabValue === 2) {
            const limit = 10;
            const data = rows.slice(0, limit)
            if (rows.length > limit) {
                if (rows.length > limit) {
                    const others_data = rows.slice(limit);
                    let others_obj = {
                        "Other Recalls": 0,
                        "Other Units": 0,
                        "Total Recalled Units": 0,
                        "Total Recalls": 0,
                        'Software Related Recalls': 0,
                        "Category": 'Others',
                        "parent_make": selectedRecallOem
                    };
                    for (let i = 0; i < others_data.length; i++) {
                        let obj = others_data[i];
                        others_obj["Other Recalls"] += obj["Other Recalls"]
                        others_obj["Other Units"] += obj["Other Units"]
                        others_obj["Total Recalled Units"] += obj["Total Recalled Units"]
                        others_obj["Total Recalls"] += obj["Total Recalls"]
                        others_obj["Software Related Recalls"] += obj["Software Related Recalls"]
                    }
                    data.push(others_obj)
                }
            }
            setFilteredRows(data)
        } else {
            setFilteredRows(null);
        }
    }, [rows, selectedRecallOem, tabValue])

    useEffect(() => {
        if (width < 768) {
            const newSize2 = {width: '99%', height: 600};
            setTimeout(() => {
                const rechartsLegendWrapper = document.getElementsByClassName('recharts-legend-wrapper')[0];
                setSize({...newSize2, height: rechartsLegendWrapper.offsetHeight + window.innerWidth})
            }, 300)
        }
    }, [filteredRows]);

    return (
        <div className={styles.simplePieChart}>
            <Box mb={2}>
                <Typography component='h3' align='center'>
                    <Box sx={{fontWeight: '600'}}>
                        {recallDropdownData.selectedRecallCause} by Cause
                        from {recallDropdownData.time[0] ? new Date(recallDropdownData.time[0]).toLocaleDateString("en-US") : 'X'} to {recallDropdownData.time[1] ? new Date(recallDropdownData.time[1]).toLocaleDateString("en-US") : 'Y'} for {recallDropdownData.selectedRecallOem ? recallDropdownData.selectedRecallOem : 'ALL MAKES'}
                    </Box>
                </Typography>
            </Box>
            {filteredRows && <ResponsiveContainer width={size.width} height={size.height}>
                <PieChart width={400} height={400}>
                    <Pie
                        data={filteredRows}
                        labelLine={false}
                        fill="#8884d8"
                        dataKey="Total Recalls"
                        cx="50%"
                        cy="50%"
                        label={customLabel}>
                        {filteredRows.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend
                        content={customLegend}
                        layout={width > 768 ? "vertical" : "horizontal"}
                        verticalAlign={width > 768 ? "middle" : 'bottom'}
                        align="right"/>
                </PieChart>
            </ResponsiveContainer>}
        </div>
    );
}

export default memo(SimplePieChart);
