import React, {memo} from 'react';

// libs
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {makeStyles} from "@material-ui/core/styles";

// assets
import styles from './Dropdown.module.scss';
import {FormControl, FormGroup, Grid, InputLabel, ListItemText, TextField} from '@mui/material';
import DropdownCustomDate from "../DropdownCustomDate/DropdownCustomDate";
import CheckIcon from "@mui/icons-material/Check";
import {Autocomplete} from "@mui/lab";

export const useStyles = makeStyles(() => ({
    formControl: {
        textAlign: 'left',
        display: 'flex !important',
        flexDirection: 'row !important',
        gap: '5px',
        flexWrap: 'wrap'
    }
}));

const OemDropdowns = ({
                          setData,
                          makeRows,
                          recallDropdownData,
                          setRecallDropdownData,
                          setPage,
                          tabValue,
                          setValueSelect,
                          valueSelect,
                          dateRangeValue,
                          setDateRangeValue
                      }) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState('');

    return (
        <div className={styles.dropdown}>
            <FormGroup className={classes.formControl} sx={{m: 1, minWidth: 200}}>
                <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm='auto'>
                        <DropdownCustomDate
                            setData={setData}
                            setRecallDropdownData={setRecallDropdownData}
                            recallDropdownData={recallDropdownData}
                            setPage={setPage}
                            tabValue={tabValue}
                            valueSelect={valueSelect}
                            setValueSelect={setValueSelect}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}/>
                    </Grid>
                    <Grid item xs={12} sm='auto'>
                        <FormControl fullWidth>
                            <InputLabel>Software Related</InputLabel>
                            <Select label='Software Related' value={recallDropdownData.selectedRecallCause}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setRecallDropdownData({
                                            ...recallDropdownData,
                                            selectedRecallCause: e.target.value
                                        })
                                    }}>
                                <MenuItem value='Software Related Recalls'>
                                    <CheckIcon/>
                                    <ListItemText primary='Software Related Recalls'/>
                                </MenuItem>
                                <MenuItem value='All Recalls'>
                                    <CheckIcon/>
                                    <ListItemText primary='All Recalls'/>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm='auto'>
                        <Autocomplete
                            sx={{minWidth: '250px'}}
                            fullWidth
                            value={recallDropdownData.selectedRecallOem}
                            onChange={(event, newValue) => {
                                    setRecallDropdownData({
                                        ...recallDropdownData,
                                        selectedRecallOem: newValue
                                    });
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                            options={makeRows}
                            renderInput={(params) => <TextField {...params} label="ALL MAKES"/>}/>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    );
}

export default memo(OemDropdowns);
