import React, {useEffect, useState} from 'react';

// libs
import cn from 'classnames';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Alert, InputAdornment, Pagination, Snackbar, Stack, TextField} from "@mui/material";
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

// components
import {EnhancedTableHead} from "./components/EnhancedTableHead";

// assets
import styles from './EnhancedTable.module.scss';

const toastPosition = {vertical: 'top', horizontal: 'right'}

const downloadFile = (fileName, urlData) => {

    const aLink = document.createElement('a');
    aLink.download = fileName;
    aLink.href = urlData;

    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
}

const headCellsPage1 = [
    {
        id: 'parent_make',
        disablePadding: true,
        label: 'OEM Name',
    },
    {
        id: 'Total Recalls',
        disablePadding: false,
        label: 'Total Recalls',
    },
    {
        id: 'Software Related Recalls',
        disablePadding: false,
        label: 'Software Related Recalls',
    },
    {
        id: '% Software Related Recalls',
        disablePadding: false,
        label: '% Software Related Recalls',
    },
    {
        id: 'Total Recalled Units',
        disablePadding: false,
        label: 'Total Recalled Units',
    },
    {
        id: 'Software Units',
        disablePadding: false,
        label: 'Software Recalled Units',
    },
    {
        id: '% Software Related Units',
        disablePadding: false,
        label: '% Software Related Units',
    },

];
const headCellsPage2 = [
    {
        id: 'category',
        disablePadding: true,
        label: 'Category',
    },
    {
        id: 'Total Recalls',
        disablePadding: false,
        label: 'Total Recalls',
    },
    {
        id: 'Software Related Recalls',
        disablePadding: false,
        label: 'Software Related Recalls',
    },
    {
        id: 'Total Recalled Units',
        disablePadding: false,
        label: 'Total Recalled Units',
    },
    {
        id: 'Software Units',
        disablePadding: false,
        label: 'Software Recalled Units',
    },
];

const EnhancedTable = ({
                           setPage,
                           page,
                           rows,
                           setOrder,
                           order,
                           orderBy,
                           setOrderBy,
                           tabValue,
                           searchValue,
                           setSearchValue,
                           setSelectedBar,
                           sortedRows,
                           setSortedRows,
                           clickable,
                       }) => {
    const [selected, setSelected] = useState([]);
    const [rowsPerPage,] = useState(10);
    const [toastStatus, setToastStatus] = useState({open: false, error: false})

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastStatus({open: false, error: false});
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        if (clickable) setSelectedBar(name);
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 1 ? Math.max(0, (1 + page) * rowsPerPage - sortedRows.length) : 0;

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleExport = () => {

        try {
            let csvContent = 'data:text/csv;charset=utf-8,';
            const csvString = [
                [
                    'Category',
                    'OEM Name',
                    'Total Recalls',
                    'Software Related Recalls',
                    '% Software Related Recalls',
                    'Total Recalled Units',
                    'Software Recalled Units',
                    '% Software Related Units'
                ],
                ...sortedRows.map((item) => [
                    item.category,
                    item.parent_make,
                    item['Total Recalls'],
                    item['Software Related Recalls'],
                    item['% Software Related Recalls'],
                    item['Total Recalled Units'],
                    item['Software Units'],
                    item['% Software Related Units']
                ]),
            ]
                .map((e) => e.join(','))
                .join('\n');
            csvContent += csvString;
            const encodedUri = encodeURI(csvContent);
            downloadFile('table.csv', encodedUri);
            setToastStatus({open: true, error: false})
        } catch (err) {
            console.log(err)
            setToastStatus({open: true, error: true})
        }

    }



    useEffect(() => {
        if (rows.length > 0) {
            const stabilizedThis = rows.map((el, index) => [el, index]);
            const comparator = order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
            stabilizedThis.sort((a, b) => {
                const order = comparator(a[0], b[0]);
                if (order !== 0) {
                    return order;
                }
                return a[1] - b[1];
            });
            const newRows = stabilizedThis.map((el) => el[0]);
            setSortedRows(newRows);
            setSearchValue('');
        }
        // eslint-disable-next-line
    }, [order, orderBy, rows]);

    const handleChangeSearch = (e) => {
        setSearchValue(e.target.value);
        let newRows;
        if (tabValue === 0 || tabValue === 1) {
            newRows = rows.filter(row => row.parent_make.toLowerCase().includes(e.target.value.toLowerCase()));
        } else {
            newRows = rows.filter(row => row.Category.toLowerCase().includes(e.target.value.toLowerCase()));
        }
        setSortedRows(newRows);
    }

    return (
        <>
            <Box mt={6} mb={2} sx={{maxWidth: '400px'}}>
                <TextField
                    fullWidth
                    value={searchValue}
                    label="Search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                    type='search'
                    variant="outlined"
                    onChange={handleChangeSearch}
                />
            </Box>
            <div className={cn(styles.table, {[styles.clickable]: clickable})}>
                <Box sx={{width: '100%'}}>
                    <Paper sx={{width: '100%', mb: 2}}>
                        <TableContainer>
                            <Table
                                sx={{minWidth: 850}}
                                aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    rowCount={sortedRows.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={(tabValue === 0 || tabValue === 1) ? headCellsPage1 : headCellsPage2}/>
                                <TableBody>
                                    {sortedRows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.parent_make)}
                                                    tabIndex={-1}
                                                    key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {(tabValue === 0 || tabValue === 1) ? row.parent_make : row.Category}
                                                    </TableCell>
                                                    <TableCell>{row['Total Recalls']}</TableCell>
                                                    <TableCell>{row['Software Related Recalls']}</TableCell>
                                                    {(tabValue === 0 || tabValue === 1) &&
                                                        <TableCell>{(row['% Software Related Recalls'] * 100).toFixed(1)}</TableCell>}
                                                    <TableCell>
                                                        {new Intl.NumberFormat('ja-JP', {maximumSignificantDigits: 10}).format(row['Total Recalled Units'])}
                                                    </TableCell>
                                                    <TableCell>
                                                        {new Intl.NumberFormat('ja-JP', {maximumSignificantDigits: 10}).format(row['Software Units'])}
                                                    </TableCell>
                                                    {(tabValue === 0 || tabValue === 1) &&
                                                        <TableCell>{(row['% Software Related Units'] * 100).toFixed(1)}</TableCell>}
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{height: 53 * emptyRows}}>
                                            <TableCell colSpan={6}/>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {sortedRows.length > 10 &&
                            <Stack
                                pt={2} pb={2} justifyContent="center" alignItems="center"
                                direction={{xs: 'column', sm: 'row'}} spacing={3}>
                                <Pagination
                                    center={"true"}
                                    count={Math.ceil(sortedRows.length / 10)}
                                    page={page}
                                    onChange={handleChangePage}
                                    variant="outlined"
                                    shape="rounded"/>
                                <Button variant='outlined' onClick={handleExport}>
                                    Export
                                </Button>
                            </Stack>}
                    </Paper>
                </Box>
                {!toastStatus.error &&
                    <Snackbar open={toastStatus.open} anchorOrigin={toastPosition} autoHideDuration={3000}
                              onClose={handleToastClose}>
                        <Alert onClose={handleToastClose} severity="success" sx={{width: '100%'}}>
                            File export successful!
                        </Alert>
                    </Snackbar>}
                {toastStatus.error &&
                    <Snackbar open={toastStatus.open} anchorOrigin={toastPosition} autoHideDuration={3000}
                              onClose={handleToastClose}>
                        <Alert onClose={handleToastClose} severity="error" sx={{width: '100%'}}>
                            Something went wrong! Try Again
                        </Alert>
                    </Snackbar>
                }
            </div>
        </>
    );
}

export default EnhancedTable
