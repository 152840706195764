const options = {
    method: "GET",
}
export const getRecalls = (start_date, end_date, softwareCalls) =>
    fetch(`${process.env.REACT_APP_BASE_API_URL}/recalls_grouped?api-key=${process.env.REACT_APP_API_KEY}${start_date ? `&start_date=${start_date}` : ''}${end_date ? `&end_date=${end_date}` : ''}${softwareCalls ? '&only_software_related=1' : '&only_software_related=0'}`, options)

export const getParentMakes = () =>
    fetch(`${process.env.REACT_APP_BASE_API_URL}/parent_makes?api-key=${process.env.REACT_APP_API_KEY}`)

export const getRecallsFiltered = (start_date, end_date, softwareCalls, make) =>
    fetch(`${process.env.REACT_APP_BASE_API_URL}/recalls_grouped?api-key=${process.env.REACT_APP_API_KEY}${start_date ? `&start_date=${start_date}` : ''}${end_date ? `&end_date=${end_date}` : ''}&groupby_category_also=2&only_software_related=${softwareCalls ? 1 : 0}${make ? '&parent_make=' + make : ''}`, options)
