import React from 'react';

// libs
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, FormGroup, Grid, InputLabel, ListItemText} from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";

// components
import DropdownCustomDate from "../DropdownCustomDate/DropdownCustomDate";

// assets
import styles from './DropdownAllRecalls.module.scss';

export const useStyles = makeStyles(() => ({
  formControl: {
    textAlign: 'left',
    display: 'flex !important',
    flexDirection: 'row !important',
    gap: '5px',
    flexWrap: 'wrap'
  }
}));

const DropdownAllRecalls = ({
                    setData,
                    setPage,
                    setOrder,
                    setOrderBy,
                    selectedDataSet,
                    setSelectedDataSet,
                    recallDropdownData,
                    setRecallDropdownData,
                    tabValue,
                    valueSelect,
                    setValueSelect,
                    dateRangeValue,
                    setDateRangeValue
                  }) => {
  const classes = useStyles();

  const handleDataSetChange = (e) => {
    setSelectedDataSet(e.target.value);
  }

  return (
      <div className={styles.dropdown}>
        <FormGroup className={classes.formControl} sx={{m: 1, minWidth: 200}}>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item xs={12} sm='auto'>
              <DropdownCustomDate
                  setData={setData}
                  recallDropdownData={recallDropdownData}
                  setRecallDropdownData={setRecallDropdownData}
                  setPage={setPage}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  tabValue={tabValue}
                  valueSelect={valueSelect}
                  setValueSelect={setValueSelect}
                  dateRangeValue={dateRangeValue}
                  setDateRangeValue={setDateRangeValue}/>
            </Grid>
            <Grid item xs={12} sm='auto'>
              <FormControl fullWidth>
                <InputLabel>Software Related</InputLabel>
                <Select label='Software Related' value={recallDropdownData.selectedRecallCause}
                        onChange={(e) => {
                          e.stopPropagation();
                          setRecallDropdownData({
                            ...recallDropdownData,
                            selectedRecallCause: e.target.value
                          })
                        }}>
                  <MenuItem value='Software Related Recalls'>
                    <CheckIcon/>
                    <ListItemText primary='Software Related Recalls'/>
                  </MenuItem>
                  <MenuItem value='All Recalls'>
                    <CheckIcon/>
                    <ListItemText primary='All Recalls'/>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm='auto'>
              <FormControl fullWidth>
                <InputLabel>Recalls / Units</InputLabel>
                <Select label='Recalls / Units' value={selectedDataSet} onChange={handleDataSetChange}>
                  <MenuItem value='Graph Recalls'>
                    <CheckIcon/>
                    <ListItemText primary='Graph Recalls'/>
                  </MenuItem>
                  <MenuItem value='Graph Units'>
                    <CheckIcon/>
                    <ListItemText primary='Graph Units'/>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </FormGroup>
      </div>
  );
}

export default DropdownAllRecalls;
