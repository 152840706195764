import React from 'react';

// libs
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, FormGroup, Grid, InputLabel, ListItemText} from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";

// components
import DropdownCustomDate from "../DropdownCustomDate/DropdownCustomDate";

// assets
import styles from './Dropdown.module.scss';

export const useStyles = makeStyles((theme) => ({
    formControl: {
        textAlign: 'left',
        display: 'flex !important',
        flexDirection: 'row !important',
        gap: '5px',
        flexWrap: 'wrap',
        margin: '20px 8px',
        minWidth: 200,
        [theme.breakpoints.up('md')]: {
            margin: '8px',
        },
    },
}));

const Dropdown = ({
                      setData,
                      setPage,
                      setOrder,
                      setOrderBy,
                      selectedDataSet,
                      setSelectedDataSet,
                      recallDropdownData,
                      setRecallDropdownData,
                      tabValue,
                      valueSelect,
                      setValueSelect,
                      dateRangeValue,
                      setDateRangeValue
                  }) => {
    const classes = useStyles();

    const handleDataSetChange = (e) => {
        setSelectedDataSet(e.target.value);
    }

    return (
        <div className={styles.dropdown}>
            <FormGroup className={classes.formControl} >
                <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm='auto'>
                        <DropdownCustomDate
                            setData={setData}
                            recallDropdownData={recallDropdownData}
                            setRecallDropdownData={setRecallDropdownData}
                            setPage={setPage}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                            tabValue={tabValue}
                            valueSelect={valueSelect}
                            setValueSelect={setValueSelect}
                            dateRangeValue={dateRangeValue}
                            setDateRangeValue={setDateRangeValue}/>
                    </Grid>
                    <Grid item xs={12} sm='auto'>
                        <FormControl fullWidth>
                            <InputLabel>Recalls / Units</InputLabel>
                            <Select label='Recalls / Units' value={selectedDataSet} onChange={handleDataSetChange}>
                                <MenuItem value='Graph Recalls'>
                                    <CheckIcon/>
                                    <ListItemText primary='Graph Recalls'/>
                                </MenuItem>
                                <MenuItem value='Graph Units'>
                                    <CheckIcon/>
                                    <ListItemText primary='Graph Units'/>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    );
}

export default Dropdown;
